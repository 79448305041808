.show {
    animation: showText 0.4s;
    //font-size: 14px;
}

.hide {
    animation: hideText 0.4s;
    font-size: 0px;
}

@keyframes hideText {
    from { font-size: 14px; }
    to   { font-size: 0px; }
}

@keyframes showText {
    from { font-size: 0px; }
    to   { font-size: 14px; }
}


.description {
    color: #999999;
    overflow: hidden;
    margin-top: 0px;
}