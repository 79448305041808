.MuiFormControlLabel-root.formControlLabel {
    display: flex;
    margin-left: 0;
}

.MuiTypography-body1 {
        font-size: none !important;
        font-weight: none;
        line-height: 1.3;
        letter-spacing: 0.00938em;
        padding: 1.3rem;
}

.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    font-size: 0.7rem;
}

.maps {
    height: 40vh !important;
    position: relative !important;
}